import { REACT_BE_BASE_URL } from '../constants/variables';
import { WishConfiguration } from '../pages/Sender/CreationWizard/types';

export const createCard = async (
  wishConfiguration: WishConfiguration,
  token: string,
  rejectedCallback: (error: any) => void,
) => {
  try {
    const response = await fetch(`${REACT_BE_BASE_URL}/api/card`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        // Custom CORS Headers - normally these are sent by the server, not the client
        'Access-Control-Allow-Origin': '*', // Allow all origins, or specify your frontend domain
        'Access-Control-Allow-Methods': 'PUT, OPTIONS', // Allow PUT and OPTIONS methods
        'Access-Control-Allow-Headers': 'Content-Type, Authorization', // Allow necessary headers
        'Access-Control-Allow-Credentials': 'true', // Allow credentials if needed
      },
      body: JSON.stringify(wishConfiguration),
    });

    const data = await response.json();

    return data;
  } catch (error: any) {
    rejectedCallback(error);

    return false;
  }
};
